
import advertisementsQuery from '~/graphql/queries/advertisements.gql';

export default {
  apollo: {
    advertisements: {
      query: advertisementsQuery,
    },
  },
  data: () => ({
    advertisements: [],
  }),
};

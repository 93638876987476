import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=4bc767c1&scoped=true&"
import script from "./Card.vue?vue&type=script&lang=js&"
export * from "./Card.vue?vue&type=script&lang=js&"
import style0 from "./Card.vue?vue&type=style&index=0&id=4bc767c1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bc767c1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ConcertDetailsModal: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/concert/DetailsModal.vue').default})

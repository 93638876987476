
import Swiper from 'swiper/swiper-bundle.min';
import 'swiper/swiper-bundle.min.css';

export default {
  mounted() {
    this.$nextTick(() => new Swiper(this.$refs.swiper, {
      spaceBetween: 24,
      slidesPerView: 'auto',
      centeredSlides: false,
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: true,
        snapOnRelease: true,
      },
      navigation: {
        nextEl: '.button-next',
        prevEl: '.button-prev',
      },
    }));
  },
};

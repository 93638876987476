import { render, staticRenderFns } from "./Carousel.vue?vue&type=template&id=4df90a5d&scoped=true&"
import script from "./Carousel.vue?vue&type=script&lang=js&"
export * from "./Carousel.vue?vue&type=script&lang=js&"
import style0 from "./Carousel.vue?vue&type=style&index=0&id=4df90a5d&prod&scoped=true&lang=css&"
import style1 from "./Carousel.vue?vue&type=style&index=1&id=4df90a5d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4df90a5d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsChevronLeft: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/ChevronLeft.vue').default})

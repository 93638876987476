
export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data: () => {
    return {
      selectedConcert: null,
    };
  },
  methods: {
    getCardInfo(item) {
      if (item.__typename === 'Concert') {
        this.selectedConcert = item;
      }
      this.$gtag('event', 'homepage_tile', {title: item.title, type: item.__typename});
    },
  },
};

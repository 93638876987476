import { render, staticRenderFns } from "./index.vue?vue&type=template&id=558cd860&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {StickySideButton: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/base/StickySideButton.vue').default,LayoutsHeader: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/layouts/Header.vue').default,Card: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/base/Card.vue').default,Advertisements: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/base/Advertisements.vue').default,SupportBanner: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/base/SupportBanner.vue').default,RadioCarouselItem: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/CarouselItem.vue').default,RadioCarousel: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/radio/Carousel.vue').default})


import page from '~/plugins/mixins/page';
import carouselProgramsQuery from '~/graphql/queries/radio/carouselPrograms.gql';
import verseQuery from '~/graphql/queries/verse.gql';

export default {
  auth: false,
  mixins: [page],
  apollo: {
    verse: {
      query: verseQuery,
    },
    programs: {
      query: carouselProgramsQuery,
    },
  },
  data: () => ({
    programs: [],
    verse: {},
    modalOpen: false,
  }),
  computed: {
    highlightedItems() {
      return this.page.highlightedItems?.map(item => ({
        item: {
          ...item[item.type],
          background_color: item[item.type]?.background_color,
          title: item.title,
          type: item.type,
          url: item[item.type]?.url || '/',
        },
      })) || [];
    },
    randomImage() {
      if (this.page?.images?.length) {
        const random = Math.floor(Math.random() * this.page.images.length);
        return this.page.images[random];
      } else {
        return null;
      }
    },
  },
};

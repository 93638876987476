import { render, staticRenderFns } from "./DetailsModal.vue?vue&type=template&id=6b32374c&"
import script from "./DetailsModal.vue?vue&type=script&lang=js&"
export * from "./DetailsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsMapMarker: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/MapMarker.vue').default,IconsClock: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Clock.vue').default,IconsEuro: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Euro.vue').default,PortalModal: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/base/PortalModal.vue').default})

import { render, staticRenderFns } from "./CarouselItem.vue?vue&type=template&id=7ada7c7b&"
import script from "./CarouselItem.vue?vue&type=script&lang=js&"
export * from "./CarouselItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsHeadphone: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/Headphone.vue').default,IconsArrowRight: require('/data/www/reformatorischeomroep.nl/reformatorischeomroep/nuxt/components/icons/ArrowRight.vue').default})
